import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTags = () => {
  return (
    <Helmet>
      <title>Anvic IT GHG Kalkulator</title>
      <meta name="description" content="Dowiedz się, jak Kalkulator GHG pomaga oszacować emisję gazów cieplarnianych związanych z uprawą surowców dla biopaliw. Oferujemy dokładne dane i przydatne informacje." />
      <meta name="keywords" content="GHG, kalkulator GHG, emisja gazów cieplarnianych, biopaliwa, uprawa, MRiRW" />
      <meta name="robots" content="index, follow" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content="Jak działa Kalkulator GHG? - Anvic IT" />
      <meta property="og:description" content="Dowiedz się, jak Kalkulator GHG pomaga oszacować emisję gazów cieplarnianych związanych z uprawą surowców dla biopaliw. Oferujemy dokładne dane i przydatne informacje." />
      <meta property="og:image" content="https://kalkulator.anvicit.pl/img/hero-img.jpg" />
      <meta property="og:url" content="https://kalkulator.anvicit.pl" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Jak działa Kalkulator GHG? - Anvic IT" />
      <meta name="twitter:description" content="Dowiedz się, jak Kalkulator GHG pomaga oszacować emisję gazów cieplarnianych związanych z uprawą surowców dla biopaliw. Oferujemy dokładne dane i przydatne informacje." />
      
    </Helmet>
  );
};

export default MetaTags;
