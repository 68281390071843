import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaMoon, FaSun, FaBars } from 'react-icons/fa';

const Header = ({ isDarkMode, toggleDarkMode, token, onLogout }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`p-4 shadow-md ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">
          <Link to="/calculator">Anvic IT GHG Kalkulator</Link>
        </div>
        <div className="flex items-center">
          <button onClick={toggleDarkMode} className="mr-4">
            {isDarkMode ? <FaSun className="text-yellow-500" /> : <FaMoon className="text-gray-500" />}
          </button>
          <div className="relative">
            <button onClick={toggleMenu} className="focus:outline-none">
              <FaBars className="text-xl" />
            </button>
            {menuOpen && (
              <div className={`absolute right-0 mt-2 w-48 shadow-lg rounded-md py-2 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'}`}>
                <Link to="/calculator" className="block px-4 py-2 hover:bg-green-800" onClick={() => setMenuOpen(false)}>
                  Kalkulator
                </Link>
                <Link to="/info" className="block px-4 py-2 hover:bg-green-800" onClick={() => setMenuOpen(false)}>
                  Informacje
                </Link>
                <Link to="/table" className="block px-4 py-2 hover:bg-green-800" onClick={() => setMenuOpen(false)}>
                  Tabela emisji
                </Link>                
                {token ? (
                  <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-green-800">
                    Wyloguj
                  </button>
                ) : (
                  <Link to="/login" className="block px-4 py-2 hover:bg-green-800" onClick={() => setMenuOpen(false)}>
                    Zaloguj
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
