// src/pages/NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-900 p-4">
      <div className="text-center">
        <img src={process.env.PUBLIC_URL + '/hero-img.jpg'} alt="Kalkulator GHG" className="inline-block w-1/4 mb-4" />
        <h1 className="text-5xl font-bold mb-4">404</h1>
        <p className="text-xl mb-4">Oops! Wygląda na to, że ta strona się zgubiła.</p>
        <p className="text-lg mb-4">Może wróćmy na stronę główną zanim ktoś zauważy?</p>
        <a href="/" className="text-blue-500 hover:underline">Przejdź do strony głównej</a>
      </div>
    </div>
  );
};

export default NotFoundPage;
