import React, { useState, useEffect } from 'react';
import { fetchRegions, fetchTableOneData } from '../api'; // Import the API calls
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'tailwindcss/tailwind.css';

const EmissionTable = () => {
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [crop, setCrop] = useState('rzepak');
  const [values, setValues] = useState({});
  const [emissions, setEmissions] = useState({});
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const fetchRegionsData = async () => {
      try {
        const regionsData = await fetchRegions();
        setRegions(regionsData);
      } catch (error) {
        console.error('Błąd pobierania regionów:', error);
      }
    };
    fetchRegionsData();
  }, []);

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const handleFetchData = async () => {
    if (selectedRegion) {
      try {
        const data = await fetchTableOneData(crop, selectedRegion);
        const data2 = data['table_1'];
        const newValues = {};
        const newEmissions = {}; // New object to store emissions
  
        newEmissions["1.1"] = data2['1.1']; // Populate emissions for 1.1
        for (let i = 2; i <= 22; i++) {
          newValues[`1.${i}`] = data2[`1.${i}`];
        }
        setValues(newValues);
        setEmissions(newEmissions);  // Set new emissions, including 1.1
        setIsDataFetched(true);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleInputChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const calculateEmissions = () => {
    const emissionFactors = {
      "1.4": 39.1, "1.5": crop === "rzepak" ? 756.5 : 310.6, "1.6": 3469, "1.7": 2724, "1.8": 3162,
      "1.9": 2832, "1.10": 3670, "1.11": 4348, "1.12": 1935, "1.13": 2693,
      "1.14": 544, "1.15": 95, "1.16": 1029, "1.17": 1552, "1.18": 413,
      "1.19": 5013, "1.20": 769, "1.21": 1620, "1.22": 12011
    };
  
    const newEmissions = Object.keys(values).reduce((acc, key) => {
      if (emissionFactors[key] && values[key]) {
        const factor = emissionFactors[key];
        const parsedValue = parseFloat(values[key]);
        if (!isNaN(parsedValue)) {
          acc[key] = (parsedValue * factor).toFixed(2);
        }
      }
      return acc;
    }, {});
  
    // Preserve the already set emission value for 1.1
    setEmissions(prevEmissions => ({
      ...newEmissions,
      "1.1": prevEmissions["1.1"] || values["1.1"]
    }));
  };
  
  
  

  const getTableRows = (crop) => [
    { id: "1.1", name: "Emisja glebowa", unit: "", emissionFactor: "", emissionUnit: "" },
    { id: "1.2", name: "Plon suchy", unit: "ton/ha" },
    { id: "1.3", name: "Kwasowość gleby", unit: "pH" },
    { id: "1.4", name: "Nawóz wapniowy (ekwiwalent CaCO3)", unit: "kg/ha", emissionFactor: 39.1, emissionUnit: "gCO2eq/kg" },
    { id: "1.5", name: "Materiał siewny", unit: "kg/ha", emissionFactor: crop === "rzepak" ? 756.5 : 310.6, emissionUnit: "gCO2eq/kg" },
    { id: "1.6", name: "Azotan amonu", unit: "kg N/ha", emissionFactor: 3469, emissionUnit: "gCO2eq/kg" },
    { id: "1.7", name: "Siarczan amonu", unit: "kg N/ha", emissionFactor: 2724, emissionUnit: "gCO2eq/kg" },
    { id: "1.8", name: "Azotan amonu i siarczan amonu", unit: "kg N/ha", emissionFactor: 3162, emissionUnit: "gCO2eq/kg" },
    { id: "1.9", name: "Amoniak bezwodny", unit: "kg N/ha", emissionFactor: 2832, emissionUnit: "gCO2eq/kg" },
    { id: "1.10", name: "Azotan amonowo-wapniowy", unit: "kg N/ha", emissionFactor: 3670, emissionUnit: "gCO2eq/kg" },
    { id: "1.11", name: "Azotan wapnia", unit: "kg N/ha", emissionFactor: 4348, emissionUnit: "gCO2eq/kg" },
    { id: "1.12", name: "Mocznik", unit: "kg N/ha", emissionFactor: 1935, emissionUnit: "gCO2eq/kg" },
    { id: "1.13", name: "Mocznik i azotan amonu", unit: "kg N/ha", emissionFactor: 2693, emissionUnit: "gCO2eq/kg" },
    { id: "1.14", name: "Superfosfat potrójny", unit: "kg P2O5/ha", emissionFactor: 544, emissionUnit: "gCO2eq/kg" },
    { id: "1.15", name: "Fosforyt 21% P2O5 23% SO3", unit: "kg P2O5/ha", emissionFactor: 95, emissionUnit: "gCO2eq/kg" },
    { id: "1.16", name: "Fosforan monoamonowy (MAP) 11% N 52% P2O5", unit: "kg P2O5/ha", emissionFactor: 1029, emissionUnit: "gCO2eq/kg" },
    { id: "1.17", name: "Fosforan diamonu (DAP) 18% N 46% P2O5", unit: "kg P2O5/ha", emissionFactor: 1552, emissionUnit: "gCO2eq/kg" },
    { id: "1.18", name: "Chlorek potasu 60% K2O", unit: "kg K2O/ha", emissionFactor: 413, emissionUnit: "gCO2eq/kg" },
    { id: "1.19", name: "NPK 15-15-15", unit: "kg/ha", emissionFactor: 5013, emissionUnit: "gCO2eq/kg" },
    { id: "1.20", name: "Tlenek magnezu", unit: "kg MgO/ha", emissionFactor: 769, emissionUnit: "gCO2eq/kg" },
    { id: "1.21", name: "Nawóz sodowy", unit: "kg Na/ha", emissionFactor: 1620, emissionUnit: "gCO2eq/kg" },
    { id: "1.22", name: "Środki ochrony roślin (substancja aktywna)", unit: "kg/ha", emissionFactor: 12011, emissionUnit: "gCO2eq/kg" }
  ];

  const downloadPDF = () => {
    const input = document.getElementById('tableDiv');
    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.5); // Adjusted for quality
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
        // Get today's date
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
  
        const formattedDate = `${yyyy}-${mm}-${dd}`;
        const fileName = `emisja_ghg_${selectedRegion}_${formattedDate}.pdf`;
  
        pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(fileName);
      });
  };
  

  return (
    <div className="overflow-x-auto p-4">
      <div className="flex justify-between mb-2">
        <select
          value={selectedRegion}
          onChange={handleRegionChange}
          className="border border-gray-300 rounded p-2"
        >
          <option value="">Wybierz region</option>
          {regions.map((region) => (
            <option key={region.kod_nuts} value={region.kod_nuts}>
              {region.region_name}
            </option>
          ))}
        </select>
        <select
          value={crop}
          onChange={(e) => setCrop(e.target.value)}
          className="border border-gray-300 rounded p-2 ml-2"
        >
          <option value="rzepak">Rzepak</option>
          <option value="corn">Kukurydza</option>
        </select>
        <button
          onClick={handleFetchData}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Pobierz
        </button>
        <button
          onClick={calculateEmissions}
          className="bg-green-500 text-white px-4 py-2 rounded ml-2"
        >
          Oblicz
        </button>
      </div>
      <div id="tableDiv">
        <table className="min-w-full border-collapse border border-gray-400">
          <thead>
            <tr>
              <th className="border border-gray-400 p-2">L.p.</th>
              <th className="border border-gray-400 p-2">Nazwa danych</th>
              <th className="border border-gray-400 p-2">Wartość</th>
              <th className="border border-gray-400 p-2">Jednostka</th>
              <th className="border border-gray-400 p-2">Wskaźnik emisji</th>
              <th className="border border-gray-400 p-2">Jednostka</th>
              <th className="border border-gray-400 p-2">Emisja, gCO2eq/ha</th>
            </tr>
          </thead>
          <tbody>
  {getTableRows(crop).map((row, index) => (
    <tr key={index}>
      <td className="border border-gray-400 p-2">{row.id}</td>
      <td className="border border-gray-400 p-2">{row.name}</td>
      <td className="border border-gray-400 p-2">
        <input
          type="number"
          className="w-full border border-gray-300 rounded"
          value={row.id === "1.1" ? '' : values[row.id] || ''}
          onChange={(e) => handleInputChange(row.id, e.target.value)}
          disabled={row.id === "1.1"}
        />
      </td>
      <td className="border border-gray-400 p-2">{row.unit}</td>
      <td className="border border-gray-400 p-2">{row.emissionFactor || ''}</td>
      <td className="border border-gray-400 p-2">{row.emissionUnit || ''}</td>
      <td className="border border-gray-400 p-2">{emissions[row.id] || ''}</td>
    </tr>
  ))}
</tbody>
        </table>
      </div>
      <button
        onClick={downloadPDF}
        className="bg-red-500 text-white px-4 py-2 rounded mt-4"
      >
        Pobierz PDF
      </button>
    </div>
  );
};

export default EmissionTable;
