// src/components/Footer.js
import React from 'react';

const Footer = ({ isDarkMode }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`py-4 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} text-center`}>
      &copy; {currentYear} Anvic IT
    </footer>
  );
};

export default Footer;
