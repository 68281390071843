import axios from 'axios';
const CLOUD_URL = 'https://services.amatorskasiatka.pl/api/calc';
const TOKEN_URL_CLOUD = 'https://services.amatorskasiatka.pl/api/';
const LOCAL_URL = 'http://localhost:8000/api/calc';
const TOKEN_URL = 'http://localhost:8000/api/';

const api = axios.create({
  baseURL: CLOUD_URL,
});

const authApi = axios.create({
  baseURL: TOKEN_URL_CLOUD,
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const fetchRegions = async () => {
  try {
    const response = await api.get('/regions/');
    return response.data;
  } catch (error) {
    throw new Error('Błąd pobierania regionów.');
  }
};

export const fetchRzepakEmission = async (kodNuts) => {
  try {
    const response = await api.get(`/show-end-rzepak/${kodNuts}/`);
    return response.data.emisja_z_uprawy_surowca;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      throw new Error('Wersja demo zakończona, skontaktuj się z nami! pj@anvicit.com');
    } else {
      throw new Error('Kod NUTS jest nieprawidłowy. Sprawdź kod NUTS i spróbuj ponownie.');
    }
  }
};

export const fetchCornEmission = async (kodNuts) => {
  try {
    const response = await api.get(`/show-end-corn/${kodNuts}/`);
    return response.data.emisja_z_uprawy_surowca;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      throw new Error('Wersja demo zakończona, skontaktuj się z nami! pj@anvicit.com');
    } else {
      throw new Error('Kod NUTS jest nieprawidłowy. Sprawdź kod NUTS i spróbuj ponownie.');
    }
  }
};

export const loginUser = (credentials) => authApi.post('/token/', credentials);


export const getRemainingViews = async (token) => {
  try {
    const response = await api.get('/remaining-views/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch remaining views.');
  }
};

export const fetchTableOneData = async (crop, kodNuts) => {
  try {
    const response = await api.get(`/show-table-1/${crop}/${kodNuts}/`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      throw new Error('Wersja demo zakończona, skontaktuj się z nami! pj@anvicit.com');
    } else {
      throw new Error('Kod NUTS jest nieprawidłowy. Sprawdź kod NUTS i spróbuj ponownie.');
    }
  }
};


export default api;
