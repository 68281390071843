import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Calculator from './components/Calculator';
import LoginPage from './pages/LoginPage';
import InfoPage from './pages/InfoPage';
import Header from './components/Header';
import Footer from './components/Footer'; // Import Footer component
import MetaTags from './components/MetaTags';
import NotFoundPage from './pages/NotFoundPage';
import EmissionTable from './pages/EmissionTable';

function App() {
  const [token, setToken] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleLoginSuccess = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <HelmetProvider>
      <Router>
        <div className={`min-h-screen flex flex-col ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
          <MetaTags />
          <Header
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
            token={token}
            onLogout={handleLogout}
          />
          <div className="flex-grow">
            <Routes>
              <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
              <Route path="/calculator" element={token ? <Calculator token={token} isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} /> : <Navigate to="/login" />} />
              <Route path="/table" element={token ? <EmissionTable token={token} isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} /> : <Navigate to="/login" />} />
              <Route path="/info" element={<InfoPage isDarkMode={isDarkMode} />} />
              <Route path="/" element={<Navigate to="/info" />} /> {/* Redirect main page to InfoPage */}
              <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
            </Routes>
          </div>
          <Footer isDarkMode={isDarkMode} /> {/* Add Footer component here */}
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
