import React, { useState, useEffect } from 'react';
import { fetchRegions, fetchRzepakEmission, fetchCornEmission, getRemainingViews } from '../api';
import '../App.css';
import {
  Box,
  Container,
  Typography,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
function Calculator({ token, isDarkMode, toggleDarkMode }) {
  const [kodNuts, setKodNuts] = useState('');
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [rzepakResult, setRzepakResult] = useState(null);
  const [cornResult, setCornResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState('both');
  const [warning, setWarning] = useState(null);
  const [remainingViews, setRemainingViews] = useState(null);
  const [submittedOption, setSubmittedOption] = useState(null);
  const [submittedRegion, setSubmittedRegion] = useState({});

  useEffect(() => {
    const getRegions = async () => {
      try {
        const regionsData = await fetchRegions(token);
        setRegions(regionsData);
      } catch (err) {
        setError('Błąd pobierania regionów.');
      }
    };

    const fetchRemainingViews = async () => {
      try {
        const views = await getRemainingViews(token);
        setRemainingViews(views.remaining_views);
      } catch (err) {
        setError('Failed to fetch remaining views.');
      }
    };

    getRegions();
    fetchRemainingViews();
  }, [token]);

  const handleChange = (e) => {
    setKodNuts(e.target.value);
    const selected = regions.find(region => region.kod_nuts === e.target.value);
    setSelectedRegion(selected || {});
  };

  const handleRegionChange = (e) => {
    const selected = regions.find(region => region.kod_nuts === e.target.value);
    setSelectedRegion(selected);
    setKodNuts(selected.kod_nuts);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const fetchEmissionData = async () => {
    setLoading(true);
    setError(null);
    setRzepakResult(null);
    setCornResult(null);

    try {
      if (selectedOption === 'rzepak' || selectedOption === 'both') {
        const rzepakEmission = await fetchRzepakEmission(kodNuts, token);
        setRzepakResult(rzepakEmission);
      }

      if (selectedOption === 'corn' || selectedOption === 'both') {
        const cornEmission = await fetchCornEmission(kodNuts, token);
        setCornResult(cornEmission);
      }

      setSubmittedOption(selectedOption);
      setSubmittedRegion(selectedRegion);

      // Fetch updated remaining views
      const updatedViews = await getRemainingViews(token);
      setRemainingViews(updatedViews.remaining_views);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError("Wersja demo zakończona, skontaktuj się z nami! pj@anvicit.com");
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" className={`mt-8 p-8 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <Box className="flex justify-end mb-4">
        {warning && <Typography color="warning.main">{warning}</Typography>}
        {error && <Typography color="error.main">{error}</Typography>}
      </Box>
      <Box className="flex flex-col gap-4">
      <Typography variant="h4" textAlign="center" className="mb-6">
        Kalkulator obliczania wartości zagregowanych emisji GHG dla etapu uprawy w cyklu życia biopaliw
      </Typography>
      <Typography variant="subtitle1" fontWeight="bold" textAlign="center" className="mb-6">
        Obliczanie emisji GHG z uprawy surowca w oparciu o dane z informacji MRiRW
      </Typography>
      </Box>
      <Box className="flex flex-col md:flex-row gap-6 my-4">
        <Card className="flex-grow p-6 rounded-lg shadow-md">
          <CardContent>
            <FormControl fullWidth className="flex flex-col gap-4">
              <TextField
                id="kodNuts"
                label="Wpisz Kod NUTS 3 lub wybierz region"
                variant="outlined"
                value={kodNuts}
                onChange={handleChange}
                placeholder="Wpisz Kod NUTS 3"
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                
              />
              <TextField
                select
                label="Wybierz region"
                value={selectedRegion.kod_nuts || ''}
                onChange={handleRegionChange}
                variant="outlined"
                
              >
                <MenuItem value="" disabled>Wybierz region</MenuItem>
                {regions
                  .slice()
                  .sort((a, b) => a.region_name.localeCompare(b.region_name))
                  .map((region) => (
                    <MenuItem key={region.kod_nuts} value={region.kod_nuts}>
                      {region.region_name}
                    </MenuItem>
                  ))}
              </TextField>
              <FormControl component="fieldset" className="mb-6">
                <FormLabel component="legend">Wybierz typ uprawy:</FormLabel>
                <RadioGroup row value={selectedOption} onChange={handleOptionChange}>
                  <FormControlLabel value="rzepak" control={<Radio />} label="Rzepak" />
                  <FormControlLabel value="corn" control={<Radio />} label="Kukurydza" />
                  <FormControlLabel value="both" control={<Radio />} label="Oba" />
                </RadioGroup>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={fetchEmissionData}
                disabled={loading}
                startIcon={loading && <CircularProgress size={24} />}
                className="mt-4"
              >
                {loading ? 'Ładowanie...' : 'Pokaż emisję'}
              </Button>
              {remainingViews !== null && (
        <Typography textAlign="center" className="mb-4">
          Pozostało wyników: {remainingViews}
        </Typography>
      )}
            </FormControl>
          </CardContent>
        </Card>
        <Box className="flex-grow flex flex-col gap-4">
          {rzepakResult !== null && submittedOption !== 'corn' && (
            <Card className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-700' : 'bg-green-100'}`}>
              <CardContent>
                <Typography variant="h5" fontWeight="bold" className={`${isDarkMode ? 'text-green-700' : 'text-green-700'}`}>Emisja Rzepaku</Typography>
                <Typography variant="subtitle1" className={`${isDarkMode ? 'text-gray-700' : 'text-gray-700'}`}><strong>Region:</strong> {submittedRegion.region_name}</Typography>
                <Typography variant="subtitle1" className={`${isDarkMode ? 'text-gray-700' : 'text-gray-700'}`}><strong>Kod NUTS 3:</strong> {submittedRegion.kod_nuts}</Typography>
                <Typography variant="h6" className="text-red-600"><strong>Emisja:</strong> <span>{rzepakResult}</span> gCO2eq/ha</Typography>
              </CardContent>
            </Card>
          )}
          {cornResult !== null && submittedOption !== 'rzepak' && (
            <Card className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-700' : 'bg-yellow-100'}`}>
              <CardContent>
                <Typography variant="h5" fontWeight="bold" className={`${isDarkMode ? 'text-yellow-600' : 'text-yellow-600'}`}>Emisja Kukurydzy</Typography>
                <Typography variant="subtitle1" className={`${isDarkMode ? 'text-gray-700' : 'text-gray-700'}`}><strong>Region:</strong> {submittedRegion.region_name}</Typography>
                <Typography variant="subtitle1" className={`${isDarkMode ? 'text-gray-700' : 'text-gray-700'}`}><strong>Kod NUTS 3:</strong> {submittedRegion.kod_nuts}</Typography>
                <Typography variant="h6" className="text-red-600"><strong>Emisja:</strong> <span>{cornResult}</span> gCO2eq/ha</Typography>
              </CardContent>
            </Card>
          )}
        </Box>
      </Box>
    </Container>
  );
  
}

export default Calculator;

