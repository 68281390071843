import React from 'react';
import { FaRegFilePdf, FaExternalLinkAlt, FaMailBulk } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const InfoPage = ({ isDarkMode }) => {
  return (
    <div className={`p-8 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-900'} rounded-lg shadow-lg m-4`}>
      <div className="flex justify-between mb-4">
        <h1 className="text-3xl font-bold">Jak działa Kalkulator GHG?</h1>
        <div>
          <Link to="/login">
            <button className="bg-green-700 text-white px-4 py-2 rounded hover:bg-green-600">
              Panel Klienta
            </button>
          </Link>
        </div>
      </div>
      
      <img src={process.env.PUBLIC_URL + '/hero-img.jpg'} alt="Kalkulator GHG" className="float-right w-1/3 ml-4 mb-4" />
      <p className="text-lg mb-4">
        Kalkulator GHG pozwala użytkownikom oszacować emisję gazów cieplarnianych związaną z fazą uprawy w cyklu życia biopaliw. 
        Kalkulator wykorzystuje dane dostarczone przez Ministerstwo Rolnictwa i Rozwoju Wsi (MRiRW) w celu zapewnienia dokładności i wiarygodności.
      </p>
      <h2 className="text-2xl font-bold mb-2">Proces krok po kroku:</h2>
      <ul className="list-decimal list-inside mb-4">
        <li>Wprowadź lub wybierz kod NUTS 3 regionu, w którym odbywa się uprawa.</li>
        <li>Wybierz rodzaj uprawy: Rzepak, Kukurydza lub oba.</li>
        <li>Kliknij przycisk "Pobierz", aby uzyskać dane dotyczące emisji.</li>
        <li>Wyświetl wyniki w szczegółowym formacie.</li>
      </ul>
      <h2 className="text-2xl font-bold mb-2">Przydatne linki:</h2>
      <ul className="list-disc list-inside mb-4">
        <li>
          <a href="http://www.kzr.inig.eu/file/repository/Poradnik_obliczania_wartoci_zagregowanych_emisji_GHG_-_KZR_INiG.pdf" target="_blank" rel="noopener noreferrer" className="text-green-700 hover:underline flex items-center">
            <FaRegFilePdf className="mr-2" />
            Poradnik KZR INIG
          </a>
        </li>
        <li>
          <a href="https://www.gov.pl/web/rolnictwo/krajowe-uprawy-rolne-na-biopaliwa--dane-do-obliczania-emisji" target="_blank" rel="noopener noreferrer" className="text-green-700 hover:underline flex items-center">
            <FaExternalLinkAlt className="mr-2" />
            Krajowe uprawy rolne na biopaliwa – dane do obliczania emisji
          </a>
        </li>
      </ul>
      <h2 className="text-2xl font-bold mb-2">Kontakt</h2>
      <p className="text-lg mb-4">
        Zainteresowany zakupem? Oferujemy licencje jednorazowe oraz modele płatności rocznych. W celu uzyskania indywidualnej oferty prosimy o kontakt:
      </p>
      <a className="text-green-700 hover:underline text-xl flex items-center" href="mailto:kalkulator@anvicit.com">
        <FaMailBulk className="mr-2" />
        kalkulator@anvicit.com
      </a>
    </div>
  );
};

export default InfoPage;
